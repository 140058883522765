import { Component, OnInit } from '@angular/core';


export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  children?: RouteInfo[];
}

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'nc-bank', class: '' },
  { path: '/producers', title: 'Productores', icon: 'nc-diamond', class: '' },
  {
    title: '3C',
    path: '/buttons',
    icon: 'nc-diamond', class: '',
    children: [
      {
        title: 'Consumo',
        path: '/dashboard',
        icon: 'nc-diamond', class: ''
      },
      {
        title: 'Crecimiento',
        path: '/dashboard',
        icon: 'nc-diamond', class: ''
      },
      {
        title: 'Consecuencia',
        path: '/dashboard',
        icon: 'nc-diamond', class: ''
      }
    ]
  },
  { path: '/supplies', title: 'Insumos', icon: 'nc-diamond', class: '' },
  { path: '/insurances', title: 'Seguros', icon: 'nc-diamond', class: '' }
];

@Component({
  moduleId: module.id,
  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
  public menuItems: any[];
  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
}
