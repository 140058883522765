import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable()
export class SupplyService {

  private _urlApi = environment.urlApi;

  constructor(private http: HttpClient) {
  }

  get() {
    const apiURL = `${this._urlApi}/supplies`;

    return this.http.get(apiURL);
  }

  getById(id) {
    const apiURL = `${this._urlApi}/supplyGroupType/${id}`;
    return this.http.get(apiURL);
  }

  post(supplyGroup: any) {
    const apiURL = `${this._urlApi}/supplyGroup`;

    return this.http.post(apiURL, supplyGroup);
  }

  getSummaryCampaigns() {
    const apiURL = `${this._urlApi}/supplies/summary/campaign`;

    return this.http.get(apiURL);
  }

  getSummaryByCampaignProducer(idProducer,id) {
    const apiURL = `${this._urlApi}/supplies/summary/campaign/${id}/producer/${idProducer}`;

    return this.http.get(apiURL);
  }

  getByType(idCampaign) {
    const apiURL = `${this._urlApi}/supplies/campaign/${idCampaign}/supply-types`;

    return this.http.get(apiURL);
  }

  getByProducerSupplyType(idCampaign, idProducer) {
    const apiURL = `${this._urlApi}/supplies/campaign/${idCampaign}/producer/${idProducer}/supply-types`;

    return this.http.get(apiURL);
  }

  getBySupplyType(idCampaign, idProducer, idSegmentType) {    
    const apiURL = `${this._urlApi}/supplies/campaign/${idCampaign}/producer/${idProducer}/segment/${idSegmentType}`;

    return this.http.get(apiURL);
  }

  getBySuppliesType(idCampaign, idSegmentType) {    
    const apiURL = `${this._urlApi}/supplies/campaign/${idCampaign}/segment/${idSegmentType}`;

    return this.http.get(apiURL);
  }

  getDetailBySupplyType(idCampaign, idProducer, idSegmentType) {
    const apiURL = `${this._urlApi}/supplies/detail/campaign/${idCampaign}/producer/${idProducer}/segment/${idSegmentType}`;

    return this.http.get(apiURL);
  }

  getSupplyByType(idCampaign, idSupplyType) {
    const apiURL = `${this._urlApi}/supplies/detail/campaign/${idCampaign}/type/${idSupplyType}`;

    return this.http.get(apiURL);
  }

  getSupplyUnitsPotentialByProducerSegmentType(idCampaign, idProducer, idSupplyType) {
    const apiURL = `${this._urlApi}/supplies/campaign/${idCampaign}/producer/${idProducer}/type/${idSupplyType}/units`;

    return this.http.get(apiURL);
  }

  getSupplyUnitsPotentialBySegmentType(idCampaign, idSupplyType) {
    const apiURL = `${this._urlApi}/supplies/campaign/${idCampaign}/type/${idSupplyType}/units`;

    return this.http.get(apiURL);
  }

  getSupplyPotentialByProducerSegmentType(idCampaign, idProducer, idSupplyType) {

    const apiURL = `${this._urlApi}/supplies/campaign/${idCampaign}/producer/${idProducer}/type/${idSupplyType}/potentials`;

    return this.http.get(apiURL);
  }

  getSupplyPotentialBySegmentType(idCampaign, idSupplyType) {
    const apiURL = `${this._urlApi}/supplies/campaign/${idCampaign}/type/${idSupplyType}/potentials`;

    return this.http.get(apiURL);
  }

  getTotalBillingSupplyProducer(idCampaign, idProducer) {
    const apiURL = `${this._urlApi}/supplies/campaign/${idCampaign}/producer/${idProducer}/totalbilling`;

    return this.http.get(apiURL);
  }

}
