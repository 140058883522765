import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CampaignService } from 'app/services/campaign.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [ 
        RouterModule, 
        CommonModule, 
        FormsModule,
        ReactiveFormsModule,
        NgbModule
         ],
    declarations: [ NavbarComponent ],
    exports: [ NavbarComponent ],
    providers: [CampaignService]
})

export class NavbarModule {}
