import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class CampaignService {

  private _urlApi = environment.urlApi;

  constructor(private http: HttpClient) {
  }

  public campaign: any = { idPrevious: 0, idCurrent: 0, campaign: [] };

  private campaignSource = new BehaviorSubject(this.campaign);

  currentCampaignMessage = this.campaignSource.asObservable();

  changeCampaigns(idPrevious: number, idCurrent: number, campaign: any) {
    this.campaignSource.next({ idPrevious, idCurrent, campaign });
  }

  get() {
    const apiURL = `${this._urlApi}/campaigns`;

    return this.http.get(apiURL);
  }

  getCurrent() {
    const apiURL = `${this._urlApi}/campaigns/current`;

    return this.http.get(apiURL);
  }

  getBefore() {
    const apiURL = `${this._urlApi}/campaigns/before`;

    return this.http.get(apiURL);
  }

  getCampaignProducer(idCampaign: number, idProducer: number) {
    const apiURL = `${this._urlApi}/campaigns/campaing-producer/${idCampaign}/producer/${idProducer}`;

    return this.http.get(apiURL);
  }

}
