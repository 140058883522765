import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  }, {
    path: '',
    component: AdminLayoutComponent,
  
      children: [
        {
          path: '',
          
          loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
        },
        {
          path: 'producers',
          loadChildren: () => import('./pages/producer/producer.module').then(m => m.ProducerModule)
        },
        {
          path: 'insurances',
          loadChildren: () => import('./pages/insurance/insurance.module').then(m => m.InsuranceModule)
        },
        {
          path: 'supplies',
          loadChildren: () => import('./pages/supply/supply.module').then(m => m.SupplyModule)
        },
        {
          path: 'billing',
          loadChildren: () => import('./pages/billing/billing.module').then(m => m.BillingModule)
        },
        {
          path: 'articles',
          loadChildren: () => import('./pages/article/article.module').then(m => m.ArticleModule)
        },
        {
          path: 'hectares',
          loadChildren: () => import('./pages/hectare/hectare.module').then(m => m.HectaresModule)
        },
      ]
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
]
