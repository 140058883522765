import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DEFAULT_CURRENCY_CODE, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { LoginComponent } from "./pages/login/login.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SupplyService } from "./services/supply.service";


@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AppComponent,
    LoginComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    RouterModule.forRoot(AppRoutes, {
      useHash: false,
      relativeLinkResolution: 'legacy'
    }),
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgbModule,
    BsDropdownModule.forRoot(),
  ],
  providers: [{ provide: DEFAULT_CURRENCY_CODE, useValue: 'U$S' }, SupplyService],
  bootstrap: [AppComponent],  
})
export class AppModule { }
